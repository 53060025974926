@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Sacramento&display=swap');

@font-face {
  font-family: 'Days One'; /*You can use whatever name that you want*/
  src: url('./fonts/days.ttf');
}

//Rubik DaysOne Sacramento


*:not(.icon) {
  font-family: 'Rubik', Arial, Helvetica, sans-serif !important;
}

h1, h2, h3, h4, h5 {
  font-family: 'Days One', Arial, Helvetica, sans-serif !important;
  font-weight: 500;
}

.ui.header {
  font-family: 'Days One', 'Helvetica Neue', Arial, Helvetica, sans-serif !important;
  font-weight: 500;
}

.grid-column-1 {
  min-width: 400px;
}



textarea {
  width: 100%;
  height: 100%;
  border: 0;
  resize: none;
  outline: none;

}

.textarea-black {
   background-color: #1b1c1d;
   color: white;
}


.icon-delete {
  transition: opacity 0.2s ease-in;
  color: #676060;
  margin-left: 10px !important;
  opacity: .1 !important;
  cursor: pointer;

  &:hover {
    opacity: 1 !important;
  }
}

.ui.fitted.toggle.checkbox {
  &.dark {
    input[type="checkbox"]:not(:checked) + label {
      background-color: #737373 !important;
      border-radius: 10px;
    }
  }
}

.tbody-overflow-wrap {
  td {
    overflow-wrap: anywhere;
  }
}

.grid-column-2 {
  min-width: 400px;
  padding-top: 5px;
}

.link-list {
  &.bulleted {
    &.list {
      .item:before {
        color: #4183c4;
        font-size: 1.7em;
      }
    }
  }
}

.link {
  color: #70AFFD !important;
  padding-left: 0.3em !important;
  padding-right: 0.3em !important;
  cursor: pointer;
  font-weight: normal !important;
  background-color: transparent !important;
  border: none !important;
  &:hover {
    text-decoration: underline !important;
  }
}

.icon-click {
  cursor: pointer;
}

.link-disabled {
  @extend .link;
  pointer-events: none;
  font-weight: normal !important;
  color: #525357 !important;
}

.grid-header   {
  flex-grow: 1;
  padding-left: 15px !important;
  margin-top: -21px !important;
}

.ui {
  .accordion {
    .title {
      &:not(.ui) {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}


$backgroundColor: #EDEDED;
$borderRadius: 15px;


.select-header {
  border: 0 !important;
  border-radius: $borderRadius !important;
  background: $backgroundColor !important;
  margin-left: 1em;
  padding-top: 0.8em;
  font-style: italic;
  font-size: 0.9em;
  min-width: 170px;
  overflow-wrap: anywhere !important;
  max-width: 250px;

  &.ui.selection.dropdown .menu {
    background: $backgroundColor;
    border: 0;
    border-bottom-left-radius: $borderRadius;
    border-bottom-right-radius: $borderRadius;
  }

  &.ui.selection.dropdown .menu > .item {
    overflow-wrap: break-word;
  }
}

.mainSideBar {
  a {
    padding: 10px 0 10px 0 !important;
    align-items: center !important;
    min-width: 60px !important;
    height: 65px !important;
    justify-content: center;
    display: flex !important;

    div {
      cursor: pointer;

      i {
        margin: 0 0 5px 0;
      }

      label {
        cursor: inherit;
      }

    }
  }
}

.homeSideBar {
  min-height: 100vh;
  width: 200px !important;

  div {
    display: flex !important;
    align-items: center !important;
    height: 65px;

    h3 {
      margin: 0 !important;
    }
  }

  a {
    display: flex !important;
    align-items: center !important;
    height: 65px;
  }
}


.mainSideBarLabel {
  font-size: 9px;
}

.ui.basic.segment.secondSideBar {
  padding: 0;
  min-height: 100vh;
}

.theme-dark-segment {
  background: rgb(0, 0, 0) !important;
  color: white !important;
}

.theme-light-segment {
  background: rgb(245, 245, 245) !important;
  color: black !important;
}

.theme-light-left-panel {
  background: rgb(255, 255, 255) !important;
  color: black !important;
}

.sub-text {
  font-size: 0.7em;
  color: #a7abac;
  margin-top: 5px;
}

.over-text {
  font-size: 12px;
  color: #a7abac;
  margin-top: 5px;
}

.orders-left-sideBar-item {
  display: flex;
  padding: 2.5px 15px 2.5px 15px;
  border-radius: 10px;
  border: 1px solid #e3e3e3;
  justify-content: space-between;
  height: 40px;
  transition: all .2s linear;
  margin-top: 10px;
  margin-bottom: 10px;
  align-items: center;
  font-size: 13px;
  font-family: 'Rubik', Arial, Helvetica, sans-serif !important;


  &:hover {
    border: 1px solid #376ef730;
    background: rgb(0 55 255 / 13%);
  }
}

.header-menu {
  height: 57px;
  display: flex;
  align-items: center;
}


.date-time-picker {
  width: 100%;
  height: 38px;
}

.react-datetime-picker__wrapper {
  border-radius: 3px;
  border: 1px solid rgba(34, 36, 38, .15);
}

.react-datetime-picker__inputGroup {
  padding-left: 1em;
}

